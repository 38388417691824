/**
 * The maximum count limit.
 */
const LIMIT_COUNTS = 100;

/**
 * 加载一个远程脚本
 * @param {String} src 一个远程脚本
 * @param {Function} callback 回调
 */
function loadScript(src: string, callback?: any) {
    let cb = callback;
    let result: Promise<HTMLScriptElement> | null = null;
    if (!callback) {
        result = new Promise((resolve, reject) => {
            cb = (err: Error, script: HTMLScriptElement) => {
                err ? reject(err) : resolve(script);
                cb = null;
            };
        });
    }

    const existingScript = document.getElementById(src);

    if (!existingScript) {
        const $script = document.createElement("script");
        $script.src = src;
        $script.id = src;
        $script.async = true;
        $script.state = "loading";
        $script.queueLoad = [cb];
        document.body.appendChild($script);
        const onEnd = "onload" in $script ? stdOnEnd.bind($script) : ieOnEnd.bind($script);
        onEnd($script);
    } else {
        if (existingScript.state === "loaded") {
            return Promise.resolve();
        }
        existingScript.queueLoad.push(cb);
    }

    function stdOnEnd(this: HTMLScriptElement & { state: "loaded" | "error"; queueLoad: any[] }, script: HTMLScriptElement) {
        script.onload = () => {
            this.onerror = this.onload = null;
            this.state = "loaded";
            let macCount = LIMIT_COUNTS;
            while (this.queueLoad.length && macCount-- > 0) {
                this.queueLoad.shift()(null, script);
            }
            // this.queueLoad.forEach((cb) => cb(null, script));
            // cb(null, script);
        };
        script.onerror = () => {
            this.onerror = this.onload = null;
            this.state = "error";
            let macCount = LIMIT_COUNTS;
            while (this.queueLoad.length && macCount-- > 0) {
                this.queueLoad.shift()(new Error(`Failed to load ${src}`), script);
            }
            // this.queueLoad.forEach((cb) => cb(new Error(`Failed to load ${src}`), script));
            // cb(new Error(`Failed to load ${src}`), script);
        };
    }

    function ieOnEnd(this: any, script: any) {
        script.onreadystatechange = () => {
            if (this.readyState !== "complete" && this.readyState !== "loaded") return;
            this.onreadystatechange = null;
            cb(null, script);
        };
    }

    return result;
}

// /**
//  * 顺序加载一组远程脚本
//  * @param {Array} list 一组远程脚本
//  * @param {Function} cb 回调
//  */
// export function loadScriptQueue(list: string[], cb: any) {
//     const first = list.shift() as string;
//     list.length ? loadScript(first, () => loadScriptQueue(list, cb)) : loadScript(first, cb);
// }

export default loadScript;
