import loadScript from "~~/utils/common";
const REMOTE_PATH = "//js.hsforms.net/forms/embed/v2.js";
const config = {
    portalId: "23517883",
    region: "na1",
};
let baseIndex = 0;

/**
 * api docs
 * @link https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
 *
 *
 * @link https://knowledge.hubspot.com/forms/how-can-i-share-a-hubspot-form-if-im-using-an-external-site#add-the-form-embed-code
 * @link https://developers.hubspot.com/changelog/forms-rendered-by-embed-code-are-updating
 */
export const useHubspotForm = (formId: string, options: Partial<HubspotFormOpts> = {}) => {
    if (!formId) throw Error("hbspot没有指定对应的表单");
    const id = ref();
    const ready = ref(false);
    onMounted(async () => {
        if (process.server) return;

        const { region, portalId } = config;
        // avoid reload hbspt-form.js
        // if (!hbspt || !hbspt.forms) {
        await loadScript(REMOTE_PATH);
        const hbspt = (window as any).hbspt;
        if (!hbspt || !hbspt.forms) return;
        // delay id change,
        // in case, when server render will generate id and keep alive in browser
        // so ,we sholud change value after script loaded, then will update vue vnode
        id.value = `hubspotId_form_${baseIndex++}`;
        // }
        hbspt.forms.create({
            target: `#${id.value}`,
            region: region,
            portalId: portalId,
            formId,
            onFormReady() {
                ready.value = true;
            },
            onFormSubmitted(_, value) {
                if (options.submit) options.submit(value);
            },
        });
    });
    return [id, { ready }] as const;
};

export interface HubspotFormOpts {
    submit: any;
}
